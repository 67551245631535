
import { Component, Vue } from 'vue-property-decorator'
import { apiAgentConfigSet, apiAgentConfigGet } from '@/api/setting'
import MaterialSelect from '@/components/material-select/index.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AgentMethodEdit extends Vue {
    /** S Data **/

    identity: Number = 0

    status: any = ''

    // 支付配置设置的数据
    paymentConfigData = {
        name: '', //支付名称
        account: '', //
        password: '', //
        expires_time: '', // 到期时间
        remark: '', // 备注
    }

    // 表单验证
    rules: any = {
        name: [{ required: true, message: '请输入显示名称', trigger: 'blur' }]
        
    }

    /** E Data **/

    /** S Methods **/

    // 获取支付方式数据
    getAgentConfigDetail() {
        apiAgentConfigGet({
            id: this.identity
        })
            .then((res: any) => {
                // 解构出结果的
                const result = {
                    ...res.config,
                    ...res
                }

                delete result.config


                this.paymentConfigData = result
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            this.handleAgentConfigEdit()
        })
    }

    // 编辑支付配置
    handleAgentConfigEdit() {
        const params = this.paymentConfigData
        const id: number = this.identity as number
        apiAgentConfigSet({ ...params, id })
            .then(() => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                this.$message.error('保存失败!')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        this.identity = query.id
        this.getAgentConfigDetail()
    }
    /** E Life Cycle **/

    /** S Compute Attr **/


    /** E Compute Attr **/
}
